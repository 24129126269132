import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import * as React from 'react';
import PageLayout from '../components/common/page-layout/page-layout';
import Imprint from '../components/content/imprint/imprint';
import SectionIntended from '../components/templates/section-intended/section-intended';

const ImprintPage = () => {
    const intl = useIntl();

    return (
        <PageLayout title={intl.formatMessage({ id: 'pages.imprint.title' })}>
            <SectionIntended>
                <h3 className="color-white">
                    <FormattedMessage id="pages.imprint.headline" />
                </h3>

                <Imprint />
            </SectionIntended>
        </PageLayout>
    );
};

export default ImprintPage;
