import { FormattedMessage, Link, useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import { richText } from '../../../modules/rich-text';
import { getParagraphIds } from '../../../modules/paragraphs';

import ImprintStyles from './imprint.module.scss';

const Imprint = () => {
    const intl = useIntl();
    const sections = getParagraphIds(intl.messages, 'pages.imprint.sections', 'headline', 'copytext');

    return (
        <div className={ImprintStyles.imprint}>
            {sections.map((section, sectionIndex) => {
                return (
                    <div key={sectionIndex} className={ImprintStyles.section}>
                        <h6 className="color-white">
                            <FormattedMessage id={section.headline} />
                        </h6>

                        {sections[sectionIndex].copytext.map(
                            (c: string, copytextIndex: string | number | undefined) => {
                                return (
                                    <p key={copytextIndex} className="color-white">
                                        {richText(c)}
                                    </p>
                                );
                            },
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default Imprint;
